import React, { useState } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import TableHeader from "./TableHeader";
import Cookies from "universal-cookie";
import TableRows from "./TableRows";
import { CurrencyFormatter } from "../../utils/CurrencyFormatter";
import logo from "../../assets/AutoCreditLogo.png"
const styles = StyleSheet.create({
  page: {
    padding: 20, // Apply padding to the whole page
  },
  view: {
    padding: 10,
  },
  text: {
    fontSize: 10, // Reduced font size
  },
  textBold: {
    fontSize: 13, // Increased font size
    fontWeight: 'bold',
  },
  marginBottom: {
    marginBottom: 30,
    fontSize: 10, // Reduced font size
  },
  image: {
    width:87,
    height: 60, // Adjust the height as needed
    marginBottom: 20, // Space between the image and the rest of the content
    marginLeft: 17,
  }
});

const Pdf = ({ customerData, customerPayments, arrears }) => {
  const { id } = useParams();
  const cookies = new Cookies();
  const today = new Date();
  const token = cookies.get("autoCreditCookie");

  return (
    <Document>
      <Page style={styles.page}>
        <Image style={styles.image} src={logo} />
        <View style={styles.view}>
        <Text style={styles.textBold}>AUTO CREDIT PVT.LTD </Text>
          <Text style={styles.text}>Mawathagama, Kurunegala. </Text>
          <Text style={styles.text}>contact@autocreditlk.com</Text>
          <Text style={styles.text}>075 604 1088</Text>
          <Text style={styles.text}>                   </Text>
          <Text style={styles.text}>                   </Text>
          <Text style={styles.textBold}>Installment History Report</Text>
          <Text style={styles.text}>                   </Text>
          <Text style={styles.text}>Customer Name: {customerData.name}{"\n"}</Text>
          <Text style={styles.text}>Customer ID : {customerData.customerID}{"\n"}</Text>
          <Text style={styles.text}>
            Installment Amount :{" "}
            {customerData.installmentAmount &&
              CurrencyFormatter(customerData.installmentAmount)}{" "}
            LKR{"\n"}
          </Text>
          <Text style={styles.text}>
            Paid Amount :{" "}
            {customerData.paidAmount &&
              CurrencyFormatter(customerData.paidAmount)}{" "}
            LKR{"\n"}
          </Text>
          <Text style={styles.text}>
            Remaining Amount :{" "}
            {customerData.paidAmount &&
              CurrencyFormatter(
                customerData.loanAmount - customerData.paidAmount
              )}{" "}
            LKR{"\n"}
          </Text>
          <Text style={styles.marginBottom}>
            Arrears as{" "}
            {today.toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Asia/Colombo",
            })}
            {": "}
            {arrears} LKR
          </Text>
          <TableHeader />
          <TableRows customerPayments={customerPayments} />
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
