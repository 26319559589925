// import React, { useState, useEffect } from "react";
// import axios from "axios";

// import SectionTitle from "../components/SectionTitle";
// import AddUser from "../components/AddUser";
// import UserList from "../components/UserList";
// import PendingUserList from "../components/PendingUserList";

// import BASE_URL from "../config/ApiConfig";

// import Cookies from "universal-cookie";

// const cookies = new Cookies();

// const ManageUsers = () => {
//   const [pendingUsers, setPendingUsers] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const token = cookies.get("autoCreditCookie");

//   useEffect(() => {
//     const fetchPendingUsers = async () => {
//       setLoading(true);
//       const axiosConfig = {
//         method: "get",
//         url: `${BASE_URL}collector/pending-users`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };

//       axios(axiosConfig)
//         .then((response) => {
//           setPendingUsers(response.data.pendingUsers);
//         })
//         .catch((error) => {
//           console.log(error);
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     };

//     fetchPendingUsers();
//   }, []);

//   return (
//     <div className="w-full">
//       <SectionTitle title="manage users" />
//       <div className="bg-white w-full rounded-lg drop-shadow-lg p-3 mb-5">
//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
//           <AddUser setPendingUsers={setPendingUsers} />
//         </div>
//       </div>
//       <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
//         <div className="bg-white w-full rounded-lg drop-shadow-lg p-3">
//           <UserList />
//         </div>
//         <div className="bg-white w-full rounded-lg drop-shadow-lg p-3">
//           <PendingUserList
//             pendingUsers={pendingUsers}
//             setPendingUsers={setPendingUsers}
//             loading={loading}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ManageUsers;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import SectionTitle from "../components/SectionTitle";
import AddUser from "../components/AddUser";
import UserList from "../components/UserList";
import PendingUserList from "../components/PendingUserList";
import { EditIcon, DeleteIcon, DateIcon } from "../Icons/Icon";
import BASE_URL from "../config/ApiConfig";
import CustomDateModal from "../modals/CustomDateModal";
import Cookies from "universal-cookie";
import SectionSubtitle from "../components/SectionSubtitle";
import Chip from "@mui/material/Chip";

import { Stack, Typography, Box } from "@mui/material";

const cookies = new Cookies();

const ManageUsers = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const token = cookies.get("autoCreditCookie");

  useEffect(() => {
    const fetchPendingUsers = async () => {
      setLoading(true);
      const axiosConfig = {
        method: "get",
        url: `${BASE_URL}collector/pending-users`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios(axiosConfig)
        .then((response) => {
          setPendingUsers(response.data.pendingUsers);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchPendingUsers();
  }, []);

  const markHolidaysButtonClick = () => {
    setShowDatePicker(true);
  };
  return (
    <div className="w-full">
      <SectionTitle title="manage users" />
      {/* <div className="bg-white drop-shadow-lg rounded-lg p-3 mb-5 flex justify-end sm:justify-between items-center">
        <p className="text-xl hidden sm:block">Mark Holidays</p>
        <div className="flex justify-end">
          <button
            className="bg-blue flex rounded-lg px-3 py-1"
            onClick={() => markHolidaysButtonClick()}
          >
            <DateIcon className="text-white" fontSize="small" />
            <p className="text-white uppercase font-semibold ms-2 text-sm">
              Mark Holidays
            </p>
          </button>
        </div>
      </div> */}
      <div className=" w-full  mb-5 grid lg:grid-cols-2  gap-3">
        <div className="grid bg-white rounded-lg drop-shadow-lg  p-3">
          <AddUser setPendingUsers={setPendingUsers} />
        </div>
        <div className="grid bg-white rounded-lg drop-shadow-lg  p-3">
          <SectionSubtitle title="Mark Holidays" />
          <div className="justify-center">
            <button
              className="bg-blue flex rounded-lg px-3 py-1"
              onClick={() => markHolidaysButtonClick()}
            >
              <DateIcon className="text-white" fontSize="small" />
              <p className="text-white uppercase font-semibold ms-2 text-sm">
                Mark Holidays
              </p>
            </button>
          </div>
          <div className="bg-green bg-transparent-10 rounded-lg drop-shadow-lg  p-3">
            <Box>
              <Stack direction="column" spacing={1}>
                <Typography variant="body1">
                  • Open the date picker and select the holidays (including poya
                  days) and click on the 'CONFIRM' button
                </Typography>
                <Typography variant="body1">
                  • GREEN colored days are marked as Holidays
                </Typography>
                <Typography variant="body1">
                  • Arrears are calculated with these holidays.
                </Typography>
              </Stack>
              <Box mt={2}>
                <Typography variant="body1">
                  • Arrears ={" "}
                  {`{(Total Days spent after loan start date) - (Holidays)}`} *
                  (Installment amount) - (Paid Amount)
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="bg-white w-full rounded-lg drop-shadow-lg p-3">
          <UserList />
        </div>
        <div className="bg-white w-full rounded-lg drop-shadow-lg p-3">
          <PendingUserList
            pendingUsers={pendingUsers}
            setPendingUsers={setPendingUsers}
            loading={loading}
          />
        </div>
      </div>
      {showDatePicker && (
        <CustomDateModal
          modalShow={showDatePicker}
          setModalShow={setShowDatePicker}
        />
      )}
    </div>
  );
};

export default ManageUsers;
