import React, { useState, useEffect } from "react";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { Button, CircularProgress, Snackbar, Alert } from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import SectionTitle from "../components/SectionTitle";
import SectionSubtitle from "../components/SectionSubtitle";

import Cookies from "universal-cookie";

import BASE_URL from "../config/ApiConfig";

const cookies = new Cookies();

const ReportsPage = () => {
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState({
    daily: false,
    weekly: false,
    monthly: false,
  });
  const [feedback, setFeedback] = useState({
    daily: "",
    weekly: "",
    monthly: "",
  });
  const [reports, setReports] = useState(null);
  const [open, setOpen] = useState(false);

  const token = cookies.get("autoCreditCookie");

  const getReports = () => {
    setLoading(true);

    const axiosConfig = {
      method: "GET",
      url: `${BASE_URL}report`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(axiosConfig)
      .then((response) => {
        console.log(response.data.reports);
        setReports(response.data.reports);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getReports();
  }, [feedback]);

  const triggerReport = async (type) => {
    setReportLoading({ ...reportLoading, [type]: true });
    setFeedback({ ...feedback, [type]: "" });

    const axiosConfig = {
      method: "post",
      url: `${BASE_URL}report/${type}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(axiosConfig)
      .then((response) => {
        setFeedback({
          ...feedback,
          [type]: `Report Succesfully Generated and Sent.`,
        });
      })
      .catch((error) => {
        console.error(`Error generating ${type} report:`, error);
        setFeedback({
          ...feedback,
          [type]: `Error generating ${type} report.`,
        });
      })
      .finally(() => {
        setReportLoading({ ...reportLoading, [type]: false });
        setOpen(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <SectionTitle title="Reports" />
      <SectionSubtitle title="Generate Reports" />
      <div className="bg-white p-5 rounded-xl drop-shadow-lg w-full max-h-[26rem] overflow-y-auto mb-3">
        <div className="flex gap-3">
          <Button
            variant="contained"
            color="primary"
            onClick={() => triggerReport("daily")}
            disabled={reportLoading.daily}
          >
            {reportLoading.daily ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Generate Daily Report"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => triggerReport("weekly")}
            disabled={reportLoading.weekly}
          >
            {reportLoading.weekly ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Generate Weekly Report"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => triggerReport("monthly")}
            disabled={reportLoading.monthly}
          >
            {reportLoading.monthly ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Generate Monthly Report"
            )}
          </Button>
        </div>

        <p className="py-3">
          {" "}
          * Genarated Reports will be sent to autocreditlk@gmail.com{" "}
        </p>
      </div>
      <SectionSubtitle className="mt-3" title="Download Generated Reports" />
      <div className="bg-white p-5 rounded-xl drop-shadow-lg w-full max-h-[26rem] overflow-y-auto">
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#808080"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          reports &&
          reports.map((report) => {
            var date = new Date(report.date).toLocaleDateString("en-CA", {
              timeZone: "Asia/Colombo",
            });
            return (
              <div
                className="flex items-center justify-between pb-3 mb-3 border-grey border-b "
                key={report._id}
              >
                <p className=""> <AssessmentIcon/> {report.reportType} Report of {date}</p>
                <button
                  onClick={() => window.open(report.downloadURL, "_blank")}
                  className="bg-yellow px-3 py-2 rounded-lg "
                >
                  <p className="">Download Report</p>
                </button>
              </div>
            );
          })
        )}
      </div>
      <div className="justify-center">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert
            onClose={handleClose}
            severity={
              feedback.daily.includes("Error") ||
              feedback.weekly.includes("Error") ||
              feedback.monthly.includes("Error")
                ? "error"
                : "success"
            }
          >
            {feedback.daily || feedback.weekly || feedback.monthly}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default ReportsPage;
