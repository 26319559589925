import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { CurrencyFormatter } from "../../utils/CurrencyFormatter";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 10,
    fontSize: 10, // Reduced font size
  },
  collector: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    
    textAlign: "left",
    fontSize: 10, // Reduced font size
  },
  amount: {
    width: "30%",
    textAlign: "left",
    paddingRight: 8,
    fontSize: 10, // Reduced font size
  },
});

const TableRows = ({ customerPayments }) => {
  if (!customerPayments) {
    return null; // or return a loading spinner, or some placeholder content
  }

  const rows = customerPayments.map((payment) => (
    <View style={styles.row} key={payment._id}>
      <Text style={styles.description}>
        {new Date(payment.paidDate).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Colombo",
        })}
        {new Date(payment.paidDate).toLocaleTimeString("en-US", {
          timeZone: "Asia/Colombo",
        })}
      </Text>

      <Text style={styles.amount}>
        {payment.amount && CurrencyFormatter(payment.amount)} LKR
      </Text>

      <Text style={styles.collector}>{payment.collectedBy}</Text>
    </View>
  ));

  return <Fragment>{rows}</Fragment>;
};

export default TableRows;